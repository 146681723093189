import {Id, YN} from '../types';


///////////
// Enums //
export enum AddressType { yard = 'yard', invoice = 'invoice' }


////////////////
// Interfaces //
export interface IMinimalAddress {
  street: string;
  city: string;
  lat?: number;
  lng?: number;
  country?: string;
}

export interface ICreateAddressSchema extends IMinimalAddress {
  type: AddressType,
  favorite: YN
}

export interface IUpdateAddressSchema extends ICreateAddressSchema {
  id?: Id,
  client?: Id
}


export interface IAddressRecord extends IMinimalAddress {
  id?: Id;
  type: AddressType;
  favorite: YN;
}

export interface IAddress extends IMinimalAddress {
  id?: Id;
  client?: Id;
  type: AddressType;
  favorite: YN;
}


///////////////
// functions //
export const emptyMinimalAddress: IMinimalAddress = {
  lat: 0, lng: 0,
  street: '', city: '',
  country: 'BE'
};

export function getEmptyClientAddress(clientId: Id, type = AddressType.yard): IAddress {
  return {
    client: clientId,
    ...emptyMinimalAddress,
    type,
    favorite: YN.kNo
  }
}

/////////////////////////
// Rules for Addresses //
/////////////////////////

// 1) Every client always have 2 addresses
// 2) The first is an InvoiceAddress
// 3) The second is the favorite YardAddress
// 4) Optionally followed by more YardAddresses
// 5) There can only be 1 InvoiceAddress
// 6) There always is 1 favorite YardAddress
// 7) An InvoiceAddress can not be the favorite address
// 8) The indicator separateInvoiceAddress is calculated/not stored and only for visualisation

// [enforce rule 1-7]
export function fixAddresses<T extends (ICreateAddressSchema | IUpdateAddressSchema | IAddress)>(addresses: Array<T>, clientId?: Id): Array<IAddress> {
  // get invoice address (if any)
  let invoice = addresses.find(x => x.type === AddressType.invoice);

  // get favorite yard addres or make one
  let yards = addresses.filter(x => x.type === AddressType.yard);
  let yard = yards.find(x => x.favorite === YN.kYes) ||
    yards[0] ||
    {...emptyMinimalAddress, ...invoice, type: AddressType.yard, id: undefined};
  yard.favorite = YN.kYes;

  // make an invoice address if none found [rule: 3]
  if ((!invoice) || (!invoice.street && !invoice.city)) {
    invoice = {
      ...emptyMinimalAddress, ...yard, type: AddressType.invoice, id: undefined
    };
  }

  // [rule 7]
  invoice.favorite = YN.kNo

  // if filter does not work, add hashes -> addresses.forEach(x => x.hash = hash(x));
  // make new list with correct order [rule 1,2,3,4,5]
  const newAddresses = [invoice, yard, ...addresses.filter(x => (x != yard) && (x.type != AddressType.invoice))];

  // enforce only 1 favorite yard address [rule 6] + add client id if any given
  newAddresses.forEach((x, i) => {
    x.favorite = (i > 1) ? YN.kNo : x.favorite; 
    if (clientId) (x as IAddress).client = clientId
  });

  return newAddresses;
}


// enforce [rule 8]
export function hasSeparateInvoiceAddress(addresses: Array<Partial<IAddress>>): boolean {
  // should be first address, but you never know
  let invoice = addresses.find(x => x.type === AddressType.invoice);

  // equality on city and street, if we ever have a country... (you know what to do)
  return ! (addresses.find(x =>
    (x.type === AddressType.yard) &&
    (x.city === invoice?.city) &&
    (x.street === invoice?.street))
  );
}
