import {inject, Injectable} from '@angular/core';
import {Platform} from "@ionic/angular";

export enum PlatformType {
  web = 'web',
  ios = 'ios',
  android = 'android'
}

export interface DeviceInformation {
  orientation: string;
  width: number;
  height: number;
  platforms: string[];
  ratio: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private platform = inject(Platform);

  public isMobile(): boolean {
    return this.platform.is("mobile");
  }

  public isAndroid(): boolean {
    return this.platform.is("android");
  }

  public isIos(): boolean {
    return this.platform.is("ios");
  }

  public isDesktop(): boolean {
    return this.platform.is("desktop");
  }

  public isHybrid(): boolean {
    return this.platform.is("hybrid");
  }

  public isTablet(): boolean {
    return this.platform.is("tablet");
  }

  public currentPlatform(): PlatformType {
    if (this.platform.is('hybrid')) {
      if (this.platform.is('ios')) return PlatformType.ios;
      if (this.platform.is('android')) return PlatformType.android;
    }
    return PlatformType.web;
  }

  public getDeviceInformation(): DeviceInformation {
    return {
      orientation: this.platform.isPortrait() ? "portrait" : "landscape",
      width: this.platform.width(),
      height: this.platform.height(),
      platforms: this.platform.platforms(),
      ratio: this.platform.height() / this.platform.width()
    }
  }

}
