
const kUninitializedValueSentinel = Symbol('uninitialized');

export interface Lazy<T> {
  readonly isInitialized: boolean;
  readonly value: T;
}

export interface LazyAsync<T> {
  readonly isInitialized: boolean;
  readonly isInitializing: boolean;
  readonly value: Promise<T>;
}

/**
 * Lazily initializes a value only when it is first accessed.
 *
 * If the initializer function fails, the error will be propagated to the caller
 * and the value will not be initialized. Subsequent accesses will try to initialize
 * the value again until it succeeds.
 *
 * Once the initializer function has successfully produced a value, it will be dereferenced.
 *
 * @param initializer The function that initializes the value.
 */
export function lazy<T>(initializer: (() => T)): Lazy<T> {
  return new LazyImpl(initializer);
}

class LazyImpl<T> implements Lazy<T> {
  private initializedValue: any = kUninitializedValueSentinel;
  private initializer: (() => T) | null;
  public constructor(initializer: (() => T)) { this.initializer = initializer; }
  public get isInitialized(): boolean {
    return this.initializedValue !== kUninitializedValueSentinel;
  }
  public get value(): T {
    if (this.initializedValue === kUninitializedValueSentinel) {
      this.initializedValue = this.initializer!();
      this.initializer = null;
    }
    return this.initializedValue;
  }
}

/**
 * Lazily and asynchronously initializes a value only when it is first accessed.
 *
 * If the initializer function fails, the error will be propagated to the caller
 * and the value will not be initialized. Subsequent accesses will try to initialize
 * the value again until it succeeds.
 *
 * Once the initializer function has successfully produced a value, it will be dereferenced.
 *
 * The initializer function is guaranteed to not be called again if it is already executing.
 *
 * @param initializer
 */
export function lazyAsync<T>(initializer: (() => Promise<T>)): LazyAsync<T> {
  return new LazyAsyncImpl(initializer);
}

class LazyAsyncImpl<T> implements LazyAsync<T> {
  private initializedValue: any = kUninitializedValueSentinel;
  private initializingValue: Promise<T> | null = null;
  private initializer: (() => Promise<T>) | null;
  public constructor(initializer: (() => Promise<T>)) { this.initializer = initializer; }
  public get isInitialized(): boolean {
    return this.initializedValue !== kUninitializedValueSentinel;
  }
  public get isInitializing(): boolean {
    return this.initializingValue !== null;
  }
  public get value(): Promise<T> {
    if (this.initializedValue !== kUninitializedValueSentinel) {
      return Promise.resolve(this.initializedValue);
    } else if (this.initializingValue === null) {
      this.initializingValue = this.initializer!()
        .then((value) => {
          this.initializedValue = value;
          this.initializer = null;
          return value;
        })
        .finally(() => {
          this.initializingValue = null;
        });
    }
    return this.initializingValue;
  }
}
