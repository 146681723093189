import {areArraysEqual, groupInRecordBy} from "../helpers/array";
import {isLowerKebabCaseIdentifier} from "../helpers/string";

/**
 * Software domains.
 *
 * *This is a local cache of `domains` database table*
 * * **Create**: add here, and add migration to insert entry to `domains` table. **MAKE SURE NAME LENGTH IS LESS THAN 32 CHARACTERS!**
 * * **Remove**: remove here, and add migration to delete entry from `domains` table. Related records are deleted on cascade.
 *          Denormalized fields will still include it but it will be ignored when unpacking so patching is not necessarily
 *          required. If the denormalized field is ever updated again, it will be no longer be included.
 * * **Rename**: don't ever be in this situation, but if you are, rename the domain, add migration to rename the old domain to the new domain.
 *          The updates will cascade to related records. The denormalized fields will still include the old domain and can either
 *          be patched in the database (use regexp, beware of overlapping strings) or in the unpack functions.
 * * **Length**: just shorten it, but if you really must, update the length of all domain foreign keys in the database first,
 *          then the length of all denormalized fields in the database that use it, and finally the length of name column
 *          in the `domains` table. In that order, all in one migration. Don't forget to change the constants here too.
 * @readonly
 */
export enum Domain {
  kClients = 'clients',
  kQuotes = 'quotes',
  kInvoices = 'invoices',
  kClientHub = 'client-hub',
  kProjects = 'projects',
  kAccounting = 'accounting',
  kPlanning = 'planning',
  kLibrary = 'library',
  kRegistrations = 'registrations',
  kSuppliers = 'suppliers',
  kSettings = 'settings'
}
assertDomainEnumInvariants(32);

export const kDomains: ReadonlySet<Domain> = new Set(Object.values(Domain));

export type Feature = {
  domain: Domain;
  name: string;
  code: string;
};
export type FeatureCode = string;
export type FeatureCodes = ReadonlyArray<FeatureCode>;
export type Features = ReadonlyArray<Feature>;
export type PackedFeatures = string;
export type FeatureParam = Domain | Feature | FeatureCode;
export type FeaturesParam = FeatureParam | Iterable<FeatureParam>;
export const kFeaturesByCode: ReadonlyMap<string, Feature> = new Map();

/**
 * Software features.
 *
 * *This is a local cache of `features` database table*
 * * **Create**: add here by combining the domain and feature name, and add migration to insert entry to `features` table.
 *          **MAKE SURE NAME LENGTH IS LESS THAN 32 CHARACTERS!**
 *          USE THE PROCEDURE 'addfeature(domain, name)' TO ADD A NEW FEATURE.
 * * **Remove**: remove here, and add migration to delete entry from `features` table. Related records are deleted on cascade.
 *         Denormalized company features will still include it but it will be ignored when unpacking so patching is not necessarily
 *        required. If the denormalized field is ever updated again, it will be no longer be included.
 *        USE THE PROCEDURE 'dropfeature(domain, name)' TO REMOVE A FEATURE.
 * * **Rename**: don't ever be in this situation, but if you are, rename the feature, add migration to rename the old feature to the new feature.
 *          The updates will cascade to related records. The denormalized company features will still include the old feature and can either
 *          be patched in the database (use regexp, beware of overlapping strings) or in the unpack functions.
 * * **Length**: just shorten it, but if you really must, update the length of all denormalized fields in the database that use it, then update
 *        the length of name column in the `features` table. All in one migration. Don't forget to change the constants here too.
 *
 * @readonly
 */
export const Feature = Object.freeze({
  // Accounting
  kAccountingInvoices:      defineFeature(Domain.kAccounting, 'invoices'),
  kAccountingPayments:      defineFeature(Domain.kAccounting, 'payments'),
  
  // ClientHub
  kClientHubSend:           defineFeature(Domain.kClientHub, 'send'),
  kClientHubSign:           defineFeature(Domain.kClientHub, 'sign'),
  kClientHubComments: defineFeature(Domain.kClientHub, 'comments'),

  // Planning/Agenda
  kPlanning:                defineFeature(Domain.kPlanning, 'access'),

  // Suppliers
  kSuppliersSalesPrices:    defineFeature(Domain.kSuppliers, 'sales-prices'),
  kSuppliersPurchasePrices: defineFeature(Domain.kSuppliers, 'purchase-prices'),
  kSuppliersShoppingCart:   defineFeature(Domain.kSuppliers, 'shopping-cart'),
  kSuppliersCheckout:       defineFeature(Domain.kSuppliers, 'checkout'),

  // Library
  kLibrary:                 defineFeature(Domain.kLibrary, 'access'),

  // Projects
  kProjects:                defineFeature(Domain.kProjects, 'access')
})

export const kFeaturesPerDomain: Readonly<Record<Domain, ReadonlyArray<Feature>>> = groupInRecordBy(
  Object.values(Feature),
  f => [ f.domain, f ],
  { unique: true, freeze: true }
);

function defineFeature(domain: Domain, name: string): Feature {
  if (name.length === 0) {
    throw new Error(`Feature name of domain '${domain}' is empty.`);
  } else if (name.length > 32) {
    throw new Error(`Feature name '${name}' of domain '${domain}' exceeds the maximum length of 32 characters.`);
  } else if (!isLowerKebabCaseIdentifier(name)) {
    throw new Error(`Feature name '${name}' of domain '${domain}' must be lower-cased, kebab-cased and consist of only ASCII characters.`);
  }
  const feature: Feature = Object.freeze({
    domain, name,
    get code() { return makeFeatureCode(this.domain, this.name); }
  });
  if (kFeaturesByCode.has(feature.code)) {
    throw new Error(`Feature '${feature.code}' is already defined.`);
  }
  let totalLength = -1; // account for delimiters
  for (const code of kFeaturesByCode.keys()) {
    totalLength += code.length + 1;
  }
  totalLength += feature.code.length;
  if (totalLength > 1024) {
    throw new Error(
      `Total length of all feature codes exceeds the maximum length of 1024 characters.` +
      `Add migration to increase the length of the 'features' field of 'companies' table to more than ${totalLength} characters.`
    );
  }
  (kFeaturesByCode as Map<string, Feature>).set(feature.code, feature); // sneaky
  return feature;
}

/**
 * Determines if the given feature lists are equal regardless of the order in which the features appear.
 * @param a the first feature list
 * @param b the second feature list
 * @returns
 */
export function areFeaturesEqual(a: ReadonlyArray<Feature> | PackedFeatures, b: ReadonlyArray<Feature> | PackedFeatures): boolean {
  const unpackedA = unpackFeatures(a);
  const unpackedB = unpackFeatures(b);
  return areArraysEqual(unpackedA, unpackedB);
}

export function getAllDomains(): Array<Domain> {
  return Array.from(kDomains);
}

/**
 * Get all features.
 * @returns
 */
export function getAllFeatures(): Array<Feature> {
  return Array.from(kFeaturesByCode.values());
}

/**
 * Get all feature codes.
 * @returns
 */
export function getAllFeatureCodes(): Array<string> {
  return Array.from(kFeaturesByCode.keys());
}

/**
 * Get a feature by its code.
 * @param code
 * @returns
 */
export function getFeatureByCode(code: string): Feature | undefined {
  return kFeaturesByCode.get(code);
}

/**
 * Get a feature by its code or all features in the given domain.
 * @param codeOrDomain a feature code or a domain
 * @returns
 */
export function getFeaturesByCodeOrInDomain(codeOrDomain: string, name?: string): Array<Feature> {
  if (isDomain(codeOrDomain)) {
    if (name) {
      codeOrDomain = makeFeatureCode(codeOrDomain, name);
    } else {
      const domainFeatures = kFeaturesPerDomain[codeOrDomain as Domain] || [];
      return [...domainFeatures];
    }
  }
  const feature = kFeaturesByCode.get(codeOrDomain);
  return feature ? [feature] : [];
}

/**
 * Get all features in the given domain.
 * @param domain the domain
 * @returns
 */
export function getFeaturesInDomain(domain: string): Array<Feature> {
  const domainFeatures = kFeaturesPerDomain[domain as Domain] || [];
  return [...domainFeatures];
}

/**
 * Check if the assigned features include all requested features.
 * @param assigned the assigned features
 * @param requested the requested features
 * @param otherRequested additional requested features
 * @returns
 */
export function hasFeature(assigned: Iterable<Feature> | PackedFeatures, requested: FeaturesParam, ...otherRequested: FeatureParam[]): boolean {
  let requestedList: Array<Feature> = [];
  if (typeof requested === 'string') {
    requestedList = getFeaturesByCodeOrInDomain(requested);
  } else if (Symbol.iterator in requested) {
    for (const r of requested) {
      if (typeof r === 'string') {
        const requestedPermissions = getFeaturesByCodeOrInDomain(r);
        for (const p of requestedPermissions) requestedList.push(p);
      } else {
        requestedList.push(r);
      }
    }
  } else {
    requestedList.push(requested);
  }
  for (const r of otherRequested) {
    if (typeof r === 'string') {
      const requestedPermissions = getFeaturesByCodeOrInDomain(r);
      for (const p of requestedPermissions) requestedList.push(p);
    } else {
      requestedList.push(r);
    }
  }
  const assignedList = (typeof assigned === 'string') ? unpackFeatures(assigned) : assigned;
  for (const r of requestedList) {
    let found = false;
    for (const a of assignedList) {
      if (a === r || (a.domain === r.domain && a.name === r.name)) {
        found = true;
        break;
      }
    }
    if (!found) return false;
  }
  return true;
}

/**
 * Check if the assigned features include any of the requested features.
 * @param assigned the assigned features
 * @param requested the requested features
 * @param otherRequested additional requested features
 * @returns
 */
export function hasAnyFeature(assigned: Iterable<Feature> | PackedFeatures, requested: FeaturesParam, ...otherRequested: FeatureParam[]): boolean {
  let requestedList: Array<Feature> = [];
  if (typeof requested === 'string') {
    requestedList = getFeaturesByCodeOrInDomain(requested);
  } else if (Symbol.iterator in requested) {
    for (const r of requested) {
      if (typeof r === 'string') {
        const requestedPermissions = getFeaturesByCodeOrInDomain(r);
        for (const p of requestedPermissions) requestedList.push(p);
      } else {
        requestedList.push(r);
      }
    }
  } else {
    requestedList.push(requested);
  }
  for (const r of otherRequested) {
    if (typeof r === 'string') {
      const requestedPermissions = getFeaturesByCodeOrInDomain(r);
      for (const p of requestedPermissions) requestedList.push(p);
    } else {
      requestedList.push(r);
    }
  }
  const assignedList = (typeof assigned === 'string') ? unpackFeatures(assigned) : assigned;
  for (const r of requestedList) {
    for (const a of assignedList) {
      if (a === r || (a.domain === r.domain && a.name === r.name)) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Determines if a feature exists with this code.
 * @param code
 * @returns
 */
export function hasFeatureWithCode(code: string): boolean {
  return kFeaturesByCode.has(code);
}

/**
 * Determines if a feature exists in this domain.
 * @param domain
 * @returns
 */
export function hasFeaturesInDomain(domain: string): boolean {
  return !!kFeaturesPerDomain[domain as Domain]?.length;
}

/**
 * Determines if given value is a domain.
 * @param value the value to check
 * @returns
 */
export function isDomain(value: string): value is Domain {
  return kDomains.has(value as Domain);
}

/**
 * Make a feature code from a domain and name.
 * @param domain the feature domain
 * @param name the feature name
 * @returns the feature code
 */
export function makeFeatureCode(domain: Domain, name: string): string {
  return `${domain}.${name}`;
}

/**
 * Pack features into a string.
 * @param unpackedFeatures the features to pack
 * @returns
 */
export function packFeatures(unpackedFeatures: Features | PackedFeatures | undefined): PackedFeatures {
  if (!unpackedFeatures) return '';
  else if (typeof unpackedFeatures === 'string') {
    return unpackedFeatures;
  }
  let packedFeatures = '';
  for (const f of unpackedFeatures) {
    const code = f.code;
    if (!packedFeatures) {
      packedFeatures = code;
      continue;
    }
    const index = packedFeatures.indexOf(code);
    const isDuplicate = (
      index >= 0 && // check bounds in case some strings are substrings of others
      ((index - 1) < 0 || packedFeatures[index - 1] === ',') &&
      ((index + code.length) >= packedFeatures.length || packedFeatures[index + code.length] === ',')
    );
    if (!isDuplicate) {
      packedFeatures += ',';
      packedFeatures += f;
    }
  }
  return packedFeatures;
}

/**
 * Unpack features from a string.
 * @param packedFeatures the packed features
 * @returns
 */
export function unpackFeatures(packedFeatures: Features | PackedFeatures | undefined): ReadonlyArray<Feature> {
  if (!packedFeatures) return [];
  else if (typeof packedFeatures !== 'string') {
    return packedFeatures;
  } else if (packedFeatures === '*') {
    return getAllFeatures();
  }
  const features: Array<Feature> = [];
  const featureCodes = packedFeatures.split(',');
  for (let i = 0; i < featureCodes.length; i++) {
    featureCodes[i] = featureCodes[i].trim();
    if (!featureCodes[i]) continue;
    const feature = kFeaturesByCode.get(featureCodes[i]);
    if (!feature) continue;
    // remove duplicates
    const index = features.indexOf(feature);
    if (index >= 0) continue;
    features.push(feature);
  }
  return features;
}

function assertDomainEnumInvariants(nameMaxLength: number) {
  for (const domain of Object.values(Domain)) {
    if (domain.length === 0) {
      throw new Error(`Feature name of domain '${domain}' is empty.`);
    } if (domain.length > nameMaxLength) {
      throw new Error(`Domain name '${domain}' exceeds the maximum length of ${nameMaxLength} characters.`);
    } else if (!isLowerKebabCaseIdentifier(domain)) {
      throw new Error(`Domain name '${domain}' must be lower-cased, kebab-cased and consist of only ASCII characters.`);
    }
  }
}
