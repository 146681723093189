import {Nullable, ServerResponse} from "../types"
import {CompanySubscription} from "./company"

export const kStripeURL = "https://api.stripe.com/v1";

export type StripeId = string;
export type StripePaymentMethod = string;

export interface StripeItem {
  id?: StripeId;
  price?: StripeId;
  quantity?: number;
  deleted?: boolean;
}

export interface StripePrice {
  id: StripeId;
  interval: StripeInterval;
  price: number;
}

export enum StripeInterval {
  month = "month",
  year = "year",
  week = "week",
  day = "day",
  other = "other"
}

export interface StripeProduct {
  id: StripeId;
  name: string;
  description: string;
  option: boolean;
  selected: boolean;
  price: StripePrice;
  includes: Array<StripeProduct>;
  trialPeriod: number;
}
export const kEmptyStripeProduct = {
  id: '',
  name: '',
  description: '',
  option: false,
  selected: false,
  price: {
    id: '',
    interval: StripeInterval.year,
    price: 0
  },
  includes: [] as Array<StripeProduct>,
  trialPeriod: 0
}

export enum StripePhase {
  none = "none",
  free = "free",

  trial = "trial", // trialing in stripe

  selecting = "selecting", // created without trial, user needs to set payment method asap

  trialPastDue = "trialPastDue", // past_due in stripe
  pastDue = "pastDue", // past_due in stripe

  paid = "paid", // active in stripe

  unpaid = "unpaid", // unpaid in stripe
  stopped = "stopped" // canceled in stripe
}

export enum PaymentMethod {
  sepa_debit = "sepa_debit",
  bancontact = "bancontact"
}

export interface PaymentDetails {
  method: PaymentMethod;
  name: string;
  number: string;
}
export const kEmptyPaymentDetails = {
  method: PaymentMethod.sepa_debit,
  name: "",
  number: ""
}

export enum StripeDiscountDuration {
  forever = "forever",
  once = "once",
  repeating = "repeating"
}
export interface StripeCoupon {
  id: StripeId;
  name: string;
  amount: number;
  percent: number;
  duration: StripeDiscountDuration;
  durationInMonths: number;
  maxRedemptions: number;
  redeemBy: number;
  timesRedeemed: number;
  promotions?: Array<StripePromotion>;
}
export interface StripePromotion {
  id: StripeId;
}


/* sample:
{
      city: 'Brothers',
      country: 'US',
      line1: '27 Fredrick Ave',
      postal_code: '97712',
      state: 'CA'
    }
*/

export interface StripeAddress {
  city: string;
  country: string;
  line1: string;
  line2?: string;
  postal_code: string;
  state: string;
}

export interface StripeCustomer {
  id?: StripeId;  // for existing customers
  name: string;
  email: string;
  tax_id_data?: Array<{type: 'eu_vat', value: string}>;
  shipping?: {
    address: StripeAddress;
    name: string;
  };
  address: StripeAddress;
  preferred_locales: Array<string>;
}

export interface SubscriptionState {
  changed: boolean;
  options: Array<StripeId>;
  total: number;
  period: StripeInterval;
  customer: StripeId;
  end: Date | string;
  payment: StripePaymentMethod;
  phase: StripePhase;
}

export interface StripePortalSession {
  customer: StripeId;
  url: string;
}

export interface StripeInvoice {
  id: StripeId;
  nr: string;
  type: "invoice" | "credit_note",
  amount_due: number;
  amount_paid: number;
  collection_method: string;
  created: Date | string;
  createdCnLinkedInvoice?: Date;
  currency: string;
  customer_address?: Nullable<StripeAddress>;
  customer_name: string;
  customer_email: string;
  customer_phone?: string;
  customer_id: string;
  customer_vat_nr?: string;
  due_date: Date | string;
  footer_note?: string;
  paid: boolean;
  url: string;
  period_end: Date | string;
  period_start: Date | string;
  finalized_at: Nullable<Date | string>;
  marked_uncollectible_at: Nullable<Date | string>;
  paid_at: Nullable<Date | string>;
  voided_at: Nullable<Date | string>;
  sub_total: number;
  total_vat: number;
  total: number;
  lines: Array<StripeInvoiceLines>
}

export interface StripeInvoiceLines {
  id: StripeId,
  name?: string;
  sub_total: number,
  total_vat: number,
  total: number,
  quantity: number,
  type: string;
  end: Date | string,
  start: Date | string,
  description: string,
  product?: StripeId;
  price?: StripeId,
  proration?: boolean;
  proration_details?: Nullable<{
    credited_items?: Nullable<{
      invoice: string;
      invoice_line_items: string[];
    }>;
  }>;
  period_end?: Date | string;
  period_start?: Date | string;
  unit_amount?: Nullable<number>;
}

// Server Responses
export interface StripeProductResponse extends ServerResponse {
  products: Array<StripeProduct>;
}

export interface StripeCouponResponse extends ServerResponse {
  coupons: Array<StripeCoupon>;
}

export interface StripeInvoiceResponse extends ServerResponse {
  invoices: Array<StripeInvoice>
}

export interface StripeResponse extends ServerResponse {
  id: StripeId;
}

export interface SubscriptionResponse extends ServerResponse {
  subscription: CompanySubscription
}

export interface GetSubscriptionResponse extends ServerResponse {
  subscription: CompanySubscription
}

export interface StripePortalSessionResponse extends ServerResponse {
  session: StripePortalSession
}
