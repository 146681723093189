
/**
 * Checks if two arrays are equal regardless of the order in which their elements appear.
 * @param a the first array to compare
 * @param b the second array to compare
 * @returns
 */
export function areArraysEqual(a: ReadonlyArray<unknown>, b: ReadonlyArray<unknown>): boolean {
  return a.length === b.length && a.every(v => b.includes(v));
}

/**
 * Groups each element in the target array by a shared key.
 * @param target the array to group elements from
 * @param groupFn a function that returns a key and a value for each element in the array, or null to skip the element
 * @param options.freeze determines whether the resulting map and arrays will be frozen (default: false)
 * @param options.unique determines whether only unique values will be added to each group (default: false)
 * @returns a map of group keys to arrays of values
 */
export function groupBy<K, T, U = T>(
  target: ReadonlyArray<T>,
  groupFn: ((v: T, i: number) => [K, U] | null),
  options?: { freeze?: boolean; unique?: boolean; }
): Map<K, Array<U>> {
  const groups = new Map<K, Array<U>>();
  const unique = options?.unique ?? false;
  for (let i = 0; i < target.length; i++) {
    const value = target[i];
    const result = groupFn(value, i);
    if (!result) continue; // skip this item
    const [key, newValue] = result;
    let groupList = groups.get(key);
    if (!groupList) {
        groupList = [];
        groups.set(key, groupList);
    } else if (unique && groupList.includes(newValue)) {
      continue; // skip duplicate
    }
    groupList.push(newValue);
  }
  if (options?.freeze) {
    for (const value of groups.values()) {
      Object.freeze(value);
    }
    Object.freeze(groups);
  }
  return groups;
}

/**
 * Groups each element in the target array by a shared key. Record keys are limited to number, string, or symbol.
 * @param target the array to group elements from
 * @param groupFn a function that returns a key and a value for each element in the array, or null to skip the element
 * @param options.freeze determines whether the resulting map and arrays will be frozen (default: false)
 * @param options.unique determines whether only unique values will be added to each group (default: false)
 * @returns a record of group keys to arrays of values
 */
export function groupInRecordBy<K extends number | string | symbol, T, U = T>(
  target: ReadonlyArray<T>,
  groupFn: ((v: T, i: number) => [K, U] | null),
  options?: { freeze?: boolean; unique?: boolean; }
): Record<K, Array<U>> {
  const groups: Record<any, Array<U>> = {};
  const unique = options?.unique ?? false;
  for (let i = 0; i < target.length; i++) {
    const value = target[i];
    const result = groupFn(value, i);
    if (!result) continue; // skip this item
    const [key, newValue] = result;
    let groupList = groups[key];
    if (!groupList) {
        groupList = [];
        groups[key] = groupList;
    } else if (unique && groupList.includes(newValue)) {
      continue; // skip duplicate
    }
    groupList.push(newValue);
  }
  if (options?.freeze) {
    for (const value of Object.values(groups)) {
      Object.freeze(value);
    }
    Object.freeze(groups);
  }
  return groups;
}

/**
 * Filters out duplicate elements from the target array.
 * @param list the array to filter
 * @param equalsFn an optional function that determines if two elements are equal (default: strict equality)
 * @returns a new array with only the first appearance of each element
 */
export function unique<T>(list: ReadonlyArray<T>): Array<T> {
  return Array.from(new Set(list));
}

/**
 * Filters out duplicate elements from the target array by element value, preserving the element order.
 * @param list the array to filter
 * @param propertyName the name of the element property to use as value
 * @returns a new array with only the first appearance of each element
 */
export function uniqueBy<T extends Record<string, any>>(list: ReadonlyArray<T>, propertyName: string): Array<T>;
/**
 * Filters out duplicate elements from the target array by element value, preserving the element order.
 * @param list the array to filter
 * @param valueFn a function that returns a value for each element in the array
 * @returns a new array with only the first appearance of each element
 */
export function uniqueBy<T extends Record<string, any>>(list: ReadonlyArray<T>, valueFn: (x: T) => any): Array<T>;
export function uniqueBy<T extends Record<string, any>>(list: ReadonlyArray<T>, propertyNameOrValueFn: string | ((x: T) => any)): Array<T> {
  const valueList: Array<any> = new Array(list.length);
  if (typeof propertyNameOrValueFn === 'string') {
    const propertyName = propertyNameOrValueFn;
    for (let i = 0; i < list.length; i++) {
      valueList[i] = list[i][propertyName];
    }
  } else {
    const valueFn = propertyNameOrValueFn;
    for (let i = 0; i < list.length; i++) {
      valueList[i] = valueFn(list[i]);
    }
  }
  const uniqueSet = new Set(valueList);
  const uniqueList = new Array<T>(uniqueSet.size);
  for (let i = 0, j = 0; i < valueList.length; i++) {
    if (uniqueSet.has(valueList[i])) {
        uniqueList[j] = list[i];
        j++;
    }
  }
  return uniqueList;
}
