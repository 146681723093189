import {Injectable} from '@angular/core';
import EventEmitter from "events";
import {UserEvent} from "core/models/user";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private emitter: EventEmitter;

  constructor() {
    this.setupEmitter();
  }

  setupEmitter() {
    this.emitter = new EventEmitter();
    this.emitter.setMaxListeners(10);
  }

  // keep updated about changes
  on(action: UserEvent, listener: (args: unknown) => void) {
    this.emitter.on(action, listener);
    // console.log('userService', action, this.emitter.listenerCount(action));
    return (() => this.off(action, listener));
  }

  off(action: UserEvent, listener: (args: unknown) => void) {
    this.emitter.off(action, listener);
    // console.log('userService', action, this.emitter.listenerCount(action));
  }

  signal(action: UserEvent, data?: unknown) {
    this.emitter.emit(action, data);
    // console.log('userService', action, data);
  }
}
