import {assertCondition as assert} from "../helpers";
import {DateTime, Id, Nullable, ServerResponse, ServerStatus, SortDirectionEnum, YN} from "../types";
import {StripeId, StripeItem} from "./stripe";
import {groupBy} from "../helpers/array";
import {displayByteSize, scaleByteSize} from "../helpers/unit";
import {Features} from "./feature";
import { KnownError, assertKnownInvariant } from "./error";

////////////////////////////////////////
// Types
////////////////////////////////////////

//#region License
export enum LicenseType {
  bundle = 'bundle',
  user = 'user',
  option = 'option'
}

export enum LicenseComputeColumn {
  is_used = 'is_used'
}
export enum LicenseExpandColumn {
  user_license = 'user_license',
  plans = 'plans'
}
export enum LicenseSortColumn {
  id = 'id',
  name = 'name',
  type = 'type',
  is_active = 'is_active',
  created = 'created',
  modified = 'modified'
}

interface ISoftwareLicenseProperties {
  id?: Id;
  readonly type: LicenseType;
  name: string;
  description?: string;
  features: Features;
  storage: number;
  stripe_product?: StripeId;
  note?: string;
  is_active?: YN;
  is_singleton?: YN;
  is_used?: YN;
  original_license?: Nullable<Id>;
  version?: number;
  created?: DateTime;
  modified?: DateTime;

  plans?: ISoftwareLicensePlan[];
}

export interface ISoftwareBundleLicense extends ISoftwareLicenseProperties {
  readonly type: LicenseType.bundle;
  user_license: Id | ISoftwareUserLicense;
}
export interface ISoftwareUserLicense extends ISoftwareLicenseProperties {
  readonly type: LicenseType.user;
}
export interface ISoftwareExtraLicense extends ISoftwareLicenseProperties {
  readonly type: LicenseType.option;
}
export type ISoftwareLicense =
  | ISoftwareBundleLicense
  | ISoftwareUserLicense
  | ISoftwareExtraLicense;

export interface ISoftwareLicenseView {
  readonly id: Id;
  readonly type: LicenseType;
  name: string;
  description?: string;
  note?: string;
  features: Features;
  storage: number;
  monthly_amount: Nullable<string>;
  yearly_amount: Nullable<string>;
  readonly stripe_product?: StripeId;
  is_active?: YN;
  readonly is_used?: YN;
  readonly version?: number;
  readonly created?: DateTime;
  readonly modified?: DateTime;
}

export interface ISoftwareLicenseFetchOptions {
  // Select
  compute?: boolean | ReadonlyArray<LicenseComputeColumn>;
  expand?: boolean | ReadonlyArray<LicenseExpandColumn>;
}
export interface ISoftwareLicenseListParams extends ISoftwareLicenseFetchOptions {
  // Filter
  id?: Id | ReadonlyArray<Id>;
  stripeProduct?: StripeId | ReadonlyArray<StripeId>;
  type?: LicenseType;
  is_active?: YN;
  features?: Features;
  q?: string;
  // Sort
  sort?: LicenseSortColumn;
  sortDirection?: SortDirectionEnum;
  // Pagination
  offset?: number;
  limit?: number;
}

export interface ISoftwareLicenseResponse extends ServerResponse {
  status: ServerStatus.kOK;
  license: ISoftwareLicense;
}
export interface ISoftwareLicenseListResponse extends ServerResponse {
  status: ServerStatus.kOK;
  records: ISoftwareLicense[];
}
//#endregion

//#region License Plan
export enum LicensePlanInterval {
  month = 'month',
  year = 'year'
}

export enum LicensePlanComputeColumn {
  is_used = 'is_used'
}
export enum LicensePlanExpandColumn {
  license = 'license',
  user_license = 'license.user_license'
}
export enum LicensePlanSortColumn {
  id = 'id',
  license = 'license',
  amount = 'amount',
  interval_type = 'interval_type',
  interval_count = 'interval_count',
  is_active = 'is_active',
  created = 'created',
  modified = 'modified'
}

export interface ISoftwareLicensePlan {
  id?: Id;
  license?: Id | ISoftwareLicense;
  stripe_price?: StripeId;
  amount: string;
  interval_type: LicensePlanInterval;
  interval_count: number;
  note?: string;
  is_active?: YN;
  is_used?: YN;
  created?: DateTime;
  modified?: DateTime;
}
export type ISoftwareLicensePlanExpanded =
  & Omit<ISoftwareLicensePlan, 'license'>
  & { license: ISoftwareLicense };

export interface ISoftwareLicensePlanFetchOptions {
  compute?: boolean | ReadonlyArray<LicensePlanComputeColumn>;
  expand?: boolean | ReadonlyArray<LicensePlanExpandColumn>;
}
export interface ISoftwareLicensePlanListParams extends ISoftwareLicensePlanFetchOptions {
  // Filter
  id?: Id | ReadonlyArray<Id>;
  stripePrice?: StripeId | ReadonlyArray<StripeId>;
  license?: Id | ReadonlyArray<Id>;
  licenseType?: LicenseType;
  is_active?: YN;
  interval_type?: LicensePlanInterval;
  interval_count?: number;
  q?: string;
  // Sort
  sort?: LicensePlanSortColumn;
  sortDirection?: SortDirectionEnum;
  // Pagination
  offset?: number;
  limit?: number;
}

export interface ISoftwareLicensePlanResponse extends ServerResponse {
  plan: ISoftwareLicensePlan;
}
export interface ISoftwareLicensePlanListResponse extends ServerResponse {
  records: ISoftwareLicensePlan[];
}
//#endregion

//#region Company License Plan
export enum CompanyPlanExpandColumn {
  licenseplan = 'licenseplan',
  license = 'licenseplan.license',
  user_license = 'licenseplan.license.user_license'
}

export enum CompanyPlanSortColumn {
  id = 'id',
  plan = 'plan',
  created = 'created',
  modified = 'modified'
}

export interface ICompanyLicensePlan {
  id?: Id;
  company: Id;
  licenseplan: Id | ISoftwareLicensePlan;
  quantity: number;
  created?: DateTime;
  modified?: DateTime;
}
export type ICompanyLicensePlanExpanded =
  & Omit<ICompanyLicensePlan, 'licenseplan'>
  & { licenseplan: ISoftwareLicensePlanExpanded };

export interface ICompanyLicensePlanFetchOptions {
  expand?: boolean | ReadonlyArray<CompanyPlanExpandColumn>;
}
export interface ICompanyLicensePlanListParams extends ICompanyLicensePlanFetchOptions {
  // Filter
  id?: Id | ReadonlyArray<Id>;
  plan?: Id | ReadonlyArray<Id>;
  stripePrice?: StripeId | ReadonlyArray<StripeId>;
  license?: Id | ReadonlyArray<Id>;
  // Sort
  sort?: CompanyPlanSortColumn;
  sortDirection?: SortDirectionEnum;
  // Pagination
  offset?: number;
  limit?: number;
}

export interface ICompanyLicensePlanResponse extends ServerResponse {
  plan: ICompanyLicensePlan;
}
export interface ICompanyLicensePlanListResponse extends ServerResponse {
  records: ICompanyLicensePlan[];
}
//#endregion

//#region Company Subscription
export enum CompanyLicensePlansIssueReason {
  inactive_new_plans = 'inactive-new-plans',
  insufficient_storage = 'insufficient-storage',
  insufficient_user_licenses = 'insufficient-user-licenses',
  interval_mismatch = 'interval-mismatch',
  invalid_quantity = 'invalid-quantity',
  missing_bundle_plan = 'missing-bundle-plan',
  multiple_bundle_plans = 'multiple-bundle-plans',
  multiple_user_plans = 'multiple-user-plans',
  multiple_plans_same_license = 'multiple-plans-same-license',
  too_many_option_plans = 'too-many-option-plans',
  user_plan_mismatch = 'user-plan-mismatch'
}

export interface ICompanyLicensePlansIssue {
  affected?: ReadonlyArray<ICompanyLicensePlan>;
  reason: CompanyLicensePlansIssueReason;
  message: string;
}
//#endregion

////////////////////////////////////////
// Functions
////////////////////////////////////////

export function isLicensePlanExpanded(plan: ISoftwareLicensePlan): plan is ISoftwareLicensePlanExpanded {
  return (typeof plan.license === 'object');
}
export function isCompanyPlanExpanded(plan: ICompanyLicensePlan): plan is ICompanyLicensePlanExpanded {
  return (typeof plan.licenseplan === 'object' && isLicensePlanExpanded(plan.licenseplan));
}

export function getCompanyLicensePlanStripeItems(companyLicensePlans: ReadonlyArray<ICompanyLicensePlan>): StripeItem[] {
  return companyLicensePlans.map(plan => ({
    price: (plan.licenseplan as ISoftwareLicensePlan).stripe_price!,
    quantity: plan.quantity ?? 1
  }));
}

export function countCompanyUserLicenses(companyLicensePlans: ReadonlyArray<ICompanyLicensePlan>): number {
  return companyLicensePlans.reduce((acc, companyLicensePlan) => {
    const softwareLicensePlan = companyLicensePlan.licenseplan as ISoftwareLicensePlan;
    const softwareLicense = softwareLicensePlan.license as ISoftwareLicense;
    if (softwareLicense.type === LicenseType.user) acc += companyLicensePlan.quantity;
    return acc;
  }, 0);
}

export function getCompanyLicensePlansByType(companyLicensePlans: ReadonlyArray<ICompanyLicensePlan>, type: LicenseType): ReadonlyArray<ICompanyLicensePlan> {
  return companyLicensePlans.filter(cp => {
    const licensePlan = cp.licenseplan as ISoftwareLicensePlan;
    const license = licensePlan.license as ISoftwareLicense;
    return license.type === type;
  });
}

export function sortCompanyLicensePlans(plans: ReadonlyArray<ICompanyLicensePlan>): ICompanyLicensePlan[] {
  return [...plans].sort((a, b) => {
    const aPlan = a.licenseplan as ISoftwareLicensePlan;
    const bPlan = b.licenseplan as ISoftwareLicensePlan;
    return (
      (aPlan.is_active||YN.kNo).localeCompare(bPlan.is_active||YN.kNo) ||
      ((aPlan.created?.getTime() ?? 0) - (bPlan.created?.getTime() ?? 0))
    );
  })
}

export function validateLicensePlanAmount(amountStr?: Nullable<string>): string {
  const amount = parseFloat(amountStr || '0');
  assertKnownInvariant(!isNaN(amount), KnownError.license_plan_amount_invalid);
  assertKnownInvariant(amount >= 0, KnownError.license_plan_amount_too_small);
  return amount.toFixed(2);
}

const kMaxCompanyLicensePlans = 20;
export function validateCompanySubscription(companyPlans: ReadonlyArray<ICompanyLicensePlan>, numUsers: number, numBytesUsed: number): Array<ICompanyLicensePlansIssue> {
  const errors: ICompanyLicensePlansIssue[] = [];
  // Rule #1: The company must have exactly one bundle plan or none at all
  if (companyPlans.length === 0) return errors;
  assert(companyPlans.every(isCompanyPlanExpanded), 'The company plans must be expanded');
  const bundlePlans = companyPlans.filter(cp => cp.licenseplan.license.type === LicenseType.bundle);
  if (bundlePlans.length === 0) {
    errors.push({
      reason: CompanyLicensePlansIssueReason.missing_bundle_plan,
      message: 'The company must have a bundle plan'
    });
  } else if (bundlePlans.length > 1) {
    errors.push({
      affected: bundlePlans,
      reason: CompanyLicensePlansIssueReason.multiple_bundle_plans,
      message: 'The company must have only one bundle plan'
    });
  }
  // Rule #2: The company must have only one (active) plan per license
  const plansGroupedByLicense = groupBy(companyPlans, cp => [cp.licenseplan.license.id, cp]);
  for (const plans of plansGroupedByLicense.values()) {
    const activePlans = plans.filter(cp => cp.licenseplan.is_active === YN.kYes && cp.licenseplan.license.is_active === YN.kYes);
    if (activePlans.length > 1) {
      errors.push({
        affected: activePlans,
        reason: CompanyLicensePlansIssueReason.multiple_plans_same_license,
        message: 'The company must have only one (active) plan per license'
      });
    }
  }
  // Rule #3: All plans must have the same interval
  let intervalType = (bundlePlans[0] ?? companyPlans[0]).licenseplan.interval_type;
  let intervalCount = (bundlePlans[0] ?? companyPlans[0]).licenseplan.interval_count;
  let mismatchedPlans = companyPlans.filter(cp => cp.licenseplan.interval_type !== intervalType || cp.licenseplan.interval_count !== intervalCount);
  if (mismatchedPlans.length > 0) {
    errors.push({
      affected: mismatchedPlans,
      reason: CompanyLicensePlansIssueReason.interval_mismatch,
      message: `All company plans must have the same interval: ${intervalCount} ${intervalType}`
    });
  }
  // Rule #4: The company must have at most one user license plan and must be the user license
  // defined by the bundle license.
  const bundleLicense = bundlePlans[0]?.licenseplan.license as ISoftwareBundleLicense | undefined;
  const userPlans = companyPlans.filter(cp => cp.licenseplan.license.type === LicenseType.user);
  if (userPlans.length > 0) {
    const activeUserPlans = userPlans.filter(cp => cp.licenseplan.is_active === YN.kYes && cp.licenseplan.license.is_active === YN.kYes);
    if (activeUserPlans.length > 1) {
      errors.push({
        affected: activeUserPlans,
        reason: CompanyLicensePlansIssueReason.multiple_user_plans,
        message: 'The company must have at most one (active) user plan'
      });
    }
    let bundleUserLicenseId: number | undefined;
    if (bundleLicense?.user_license) {
      bundleUserLicenseId = (typeof bundleLicense.user_license === 'object')
        ? bundleLicense.user_license.id
        : bundleLicense.user_license;
    }
    mismatchedPlans = userPlans.filter(up => {
      const userLicenseId = (typeof up.licenseplan.license === 'object')
        ? up.licenseplan.license.id
        : up.licenseplan.license;
      return userLicenseId !== bundleUserLicenseId;
    });
    if (mismatchedPlans.length > 0) {
      errors.push({
        affected: mismatchedPlans,
        reason: CompanyLicensePlansIssueReason.user_plan_mismatch,
        message: `The company user plan must match the bundle's user plan`
      });
    }
  }
  // Rule #5: The company must have at most 18 option plans
  const optionPlans = companyPlans.filter(cp => cp.licenseplan.license.type === LicenseType.option);
  const maxOptionPlans = kMaxCompanyLicensePlans - 1 /*bundle*/ - userPlans.length;
  if (optionPlans.length > maxOptionPlans) {
    errors.push({
      affected: optionPlans,
      reason: CompanyLicensePlansIssueReason.too_many_option_plans,
      message: `The company must have at most ${maxOptionPlans} option plans`
    });
  }
  // Rule #6: The plan quantity must be at least 1 and cannot exceed 1 for singleton licenses.
  let invalidPlans = companyPlans.filter(cp => cp.quantity < 1);
  if (invalidPlans.length > 0) {
    errors.push({
      affected: invalidPlans,
      reason: CompanyLicensePlansIssueReason.invalid_quantity,
      message: 'The plan quantity must be at least 1'
    });
  }
  invalidPlans = companyPlans.filter(cp => cp.quantity > 1 && cp.licenseplan.license.is_singleton === YN.kYes);
  if (invalidPlans.length > 0) {
    errors.push({
      affected: invalidPlans,
      reason: CompanyLicensePlansIssueReason.invalid_quantity,
      message: 'The plan quantity must be exactly 1 for singleton licenses'
    });
  }
  // Rule #7: New plans (and their respective license) must be active in order to be added to the company
  const inactiveNewPlans = companyPlans.filter(cp =>
    !cp.id && (cp.licenseplan.is_active !== YN.kYes || cp.licenseplan.license.is_active !== YN.kYes)
  );
  if (inactiveNewPlans.length > 0) {
    errors.push({
      affected: inactiveNewPlans,
      reason: CompanyLicensePlansIssueReason.inactive_new_plans,
      message: 'New plans (and their respective license) must be active in order to be added to the company'
    });
  }
  // Rule #8: The company must have enough user licenses when downgrading
  const maxUsers = userPlans.reduce((acc, up) => acc + up.quantity, 0);
  if (numUsers > maxUsers) {
    errors.push({
      reason: CompanyLicensePlansIssueReason.insufficient_user_licenses,
      message: `The company does not have enough user licenses: ${maxUsers} available, ${numUsers} used`
    });
  }
  // Rule #9: The company must have enough storage space when downgrading
  const maxStorageMB = companyPlans.reduce((acc, cp) => acc + (cp.quantity * cp.licenseplan.license.storage), 0);
  const maxStorageBytes = scaleByteSize(maxStorageMB, 'MiB', 'B');
  if (numBytesUsed > maxStorageBytes) {
    errors.push({
      reason: CompanyLicensePlansIssueReason.insufficient_storage,
      message:
      `The company does not have enough storage space: ` +
      `${displayByteSize(maxStorageBytes, 'B', 'GiB')} available, ` +
      `${displayByteSize(numBytesUsed, 'B', 'GiB')} used`
    });
  }
  return errors;
}
