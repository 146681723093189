import {inject, Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {UserService} from '../services/user.service';
import {ToastService} from '../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataResolver {
  private authService = inject(AuthService);
  private userService = inject(UserService);
  private toastService = inject(ToastService);

  async resolve(): Promise<boolean> {
    try {
      const userResponse = await this.authService.getDBUser();
      this.userService.setUserData(userResponse.user);

      await this.toastService.presentToast('DataResolver -> ' + userResponse.user, 'primary', 5000);

    } catch (e: any) {
      await this.toastService.presentToast('DataResolver -> catch: ' + e.message, 'primary', 5000);

    }
    return true;
  }
}
