export function makeVersions(version: string): Array<number> {
  const versions = version.split(".");
  if (versions.length < 2) versions.push("0");
  if (versions.length < 3) versions.push("0");
  return versions.map(v => parseInt(v, 10));
}

export function makeVersionString(versions: Array<number>): string {
  return versions.join(".");
}

export function assertVersion(v1: string, v2: string): boolean {
  const versions1 = makeVersions(v1||"99.99.99");
  const versions2 = makeVersions(v2||"0.0.0");

  for (let i = 0; i < 3; i++) {
    if (versions1[i] < versions2[i]) return false;
    if (versions1[i] > versions2[i]) return true;
  }
  return true;
}

export function makeBuild(version: Array<number>, release: number): number {
  return version[0] * 100 * 100 * 1000 + version[1] * 100 * 1000 + version[2] * 1000 + release * 1;
}
