export function awsMessage(message: string): string {
  switch (message) {
    case 'Invalid voucher':
      return 'cognito.invalidVoucher';

    case 'User does not exist.':
    case 'User does not exist':
    case 'User not found':
    case 'Username/client id combination not found.':
      return 'cognito.userNotFound';

    case 'User already exists':
    case 'An account with the given e-mail already exists.':
      return 'cognito.userAlreadyExists';

    case 'Incorrect username or password.':
    case 'Invalid password':
      return 'cognito.incorrectUsernameOrPassword';

    case 'User is not confirmed.':
      return 'cognito.userNotConfirmed';

    case 'User is disabled.':
      return 'cognito.userDisabled';

    case 'User is not authenticated.':
    case 'User is not authenticated':
      return 'cognito.userNotAuthenticated';

    case 'PASSWORD parameter is required':
      return 'cognito.passwordRequired';

    case 'User not authorized':
      return 'cognito.userNotAuthorized';

    case 'Invalid password':
      return 'cognito.invalidPassword';

    case 'Invalid verification code provided, please try again.':
      return 'cognito.invalidVerificationCode';

    case 'User is already confirmed.':
    case 'User cannot be confirmed. Current status is CONFIRMED':
      return 'cognito.userAlreadyConfirmed';

    case 'Invalid code received for user':
      return 'cognito.codeMismatch';

    default:
      return 'cognito.defaultMessage';
  }
}
