import distro from "./distro-settings.json";

//////////////////////
// BlueDevStack //
//////////////////////

export const environment = {
  name: "buildmate development",
  productName: "buildmate",
  productWebsite: "https://buildmate.be",
  applicationWebsite: distro.dev.clientUrl,
  clientHubWebsite: distro.dev.clienthubUrl,
  production: false,
  development: true,
  staging: true,
  logo: "Icon_Buildmate_RGB_dev.svg",
  appId: "be.buildmate.dev",
  userPilot: "STG-NX-16a87389",
  server: distro.dev.apiUrl,
  cognito: {
    authenticationFlow: distro.dev.authenticationFlow,
    endpoint: distro.dev.userPoolUrl,
    region: distro.dev.userPoolRegion,
    userPoolId: distro.dev.userPoolId,
    userPoolClientId: distro.dev.userPoolClientId
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;
