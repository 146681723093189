import {LineType, Nullable, VATRate} from "./types";
import {IDocumentLine} from "./models/document";
import {SettingNames, SupportedLanguages} from "./models/setting";
import {SettingsConfig} from "./models/setting-config";


export function round(nr: number, digits = 2): number {
  const power = 10 ** digits;
  return Math.round((nr + Number.EPSILON) * power) / power;
}

export function equal(x: number, y: number, tolerance = Number.EPSILON) {
  return Math.abs(x - y) < tolerance;
}
export function different(x: number, y: number, tolerance = Number.EPSILON) {
  return !equal(x, y, tolerance);
}

export function keepAlphanumericChars(inputString?: Nullable<string>): string {
  return inputString?.replace(/[^a-zA-Z0-9]/g, '') || "";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function safeParse(str: string, def: any = {}): any {
  try {
    return JSON.parse(str) || def;
  } catch (e) {
    return def;
  }
}

export function merge(dest: any, src: any) {
  Object.keys(src).forEach(name => {
    if (typeof src[name] !== 'object' || Array.isArray(src[name]) || !src[name]) {
      dest[name] = src[name]
    } else {
      merge(dest[name], src[name])
    }
  });
}


export function replaceSecretKeys(config: any, secretKeys: {[key: string]: string}) {
  Object.keys(config).forEach(name => {
    if (typeof config[name] === 'object') {
      replaceSecretKeys(config[name], secretKeys);

    } else if ((typeof config[name] === 'string') && (config[name].indexOf("secret:") === 0)) {
      const key = config[name].substring(7);
      if (key in secretKeys) {
        console.log("replacing: " + name + " with secret value of " + key);
        config[name] = secretKeys[key];
      } else {
        console.log("WARNING: secret key not found: " + key);
        config[name] = "-- " + key + " not found --";
      }
    }
  });
}

export function getHash(obj: any): string {
  // Examples:
  // console.log(getHash({ 1: 3, 3: 7 })); -> '1337'
  // console.log(getHash([1, 3, 3, 7]));   -> '01132337'
  // console.log(getHash('Blue Team'));    -> 'Blue Team'

  let hash = '';
  if (typeof obj !== 'object')
    return obj; // return value

  for (var prop in obj)
    hash += prop + getHash(obj[prop]); // Add key + value
  return hash;
}

export function padZeros(nr: number, zeros = 0): string {
  const padding = zeros - Math.log10(nr + 1);
  return '0'.repeat(padding >= 0 ? padding : 0) + nr;
}

export function documentNr(prefix: string, nr: number): string {
  if (nr === 0) return '-';
  return `${prefix}${padZeros(nr || 0, 4)}`;
}

export function projectNr(nr: number): string {
  return 'P-' + padZeros(nr || 0, 3);
}


interface sortable { position: number }

export function moveItem<T extends sortable>(list: Array<T>, item: number, onto: number): Array<T> {
  // make space for the moved item
  if (onto < item) {
    list = list.map((line, inx) =>
      ((inx >= onto) && (inx < item)) ? {...line, position: line.position + 1} : line
    );
  } else {
    list = list.map((line, inx) =>
      ((inx >= item+1) && (inx <= onto)) ? {...line, position: line.position - 1} : line
    );
  }

  // if we don't copy the object, Angular doesn't detect the change
  list[item] = {...list[item], position: onto};
  list.sort((a,b) => a.position - b.position);

  return list;
}

export function nextPosition<T extends sortable>(list: Array<T>): number {
  return list.reduce((highest, current) => {
    return highest.position > current.position ? highest : current;
  }, {position: 0}).position + 1;
}

export function reorden<T extends sortable>(list: Array<T>): Array<T> {
  list.sort((a, b) => a.position - b.position).forEach((item, i) => item.position = i+1);
  return list;
}

// export function dataURLtoFile(dataUrl: string, filename: string): File {
//   const arr = dataUrl.split(',');
//   const mime = arr[0].match(/:(.*?);/)[1];
//   const bstr = atob(arr[arr.length - 1]);
//   const n = bstr.length;
//   const u8arr = new Uint8Array(n);
//   while (n) u8arr[n] = bstr.charCodeAt(n);
//   return new File([u8arr], filename, {type:mime});
// }

/**
 * @param requestParams {param: value, param2: value2}
 * @returns ?param=value&param2=value2
 */
export type IRequestParams = Record<string, number | string | boolean | Array<string> | Array<number>>;

export function buildParamString(requestParams: IRequestParams): string {
  return Object.keys(requestParams).reduce((result, key) => {
    const param = requestParams[key];
    // number 0 & boolean false worden toegevoegd
    if (param !== null && param !== undefined && param !== '') {
      result += (result.length > 0) ? '&' : '?';

      if (typeof param === 'string') {
        result += `${key}=${encodeURIComponent(param)}`;

      } else if (Array.isArray(param)) {
          result += `${key}=${encodeURIComponent(param.join(','))}`;

      } else {
        result += `${key}=${param as number}`;
      }
    }
    return result;
  }, '');
}

//////////////////////////////////////////////
// Base32 en/de-coding according to RFC4648 //
//////////////////////////////////////////////

const base32Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';   // RFC4648

export function base32(s: string, padding = true): string {
  // const buffer = Buffer.from(s)
  // const length = buffer.byteLength;
  // const view = new Uint8Array(buffer);
  const view = new TextEncoder().encode(s)
  const length = view.length;

  let bits = 0;
  let value = 0;
  let output = '';

  for (let i = 0; i < length; i++) {
    value = (value << 8) | view[i]!;
    bits += 8;

    while (bits >= 5) {
      output += base32Alphabet[(value >>> (bits - 5)) & 31];
      bits -= 5;
    }
  }

  if (bits > 0) {
    output += base32Alphabet[(value << (5 - bits)) & 31];
  }

  if (padding) {
    while (output.length % 8 !== 0) {
      output += '=';
    }
  }
  return output;
}

export function base32Decode(input: string): string {

  function readChar(char: string): number {
    const idx = base32Alphabet.indexOf(char);
    if (idx === -1) {
      throw new Error('Invalid character found: ' + char);
    }
    return idx;
  }

  // remove padding if any
  const cleanedInput = input.toUpperCase().replace(/=+$/, '');
  const length = cleanedInput.length

  let bits = 0;
  let value = 0;

  let index = 0;
  const output = new Uint8Array(((length * 5) / 8) | 0);

  for (let i = 0; i < length; i++) {
    value = (value << 5) | readChar(cleanedInput[i]!);
    bits += 5;

    if (bits >= 8) {
      output[index++] = (value >>> (bits - 8)) & 255;
      bits -= 8;
    }
  }

  return  new TextDecoder("utf-8").decode(output);
}


export function getRemainingSubscriptionDays(endDate: Date | string): number {
  return Math.round((new Date(endDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
}


// Date reviver
export function revive(field: string | Date, onlyDate = true): Nullable<Date> {
  if (!field) return null; // new Date();

  // should only be used for date fields
  if (onlyDate) {
    if (typeof field === "string") {
      // make a date object from the date part of the string
      return new Date(parseInt(field.substring(0,4)), parseInt(field.substring(5,7))-1, parseInt(field.substring(8,10)), 0, 0, 0, 0);

    } else {
      // return the date object with only the date part
      field.setHours(0, 0, 0, 0);
      return field;
    }

  } else {
    // should only be used for server generated date/times
    if (typeof field === "string") {
      // return date object with date+hh:mm:ss (we left the mS out)
      return new Date(parseInt(field.substring(0,4)), parseInt(field.substring(5,7))-1, parseInt(field.substring(8,10)),
                      parseInt(field.substring(11,13)), parseInt(field.substring(14,16)), parseInt(field.substring(17,19)), 0);

    } else {
      // just return the date object
      return field;
    }
  }
}

export function addIfSomeLines(documentLines: (IDocumentLine)[],
                               hasVat: VATRate,
                               addSetting: SettingNames,
                               language: SupportedLanguages = SupportedLanguages.nl): string {
  let footerMessages: string = '';
  if (documentLines.some(line => (line.type === LineType.item) && (line.vat === hasVat))) {
    const setting = SettingsConfig.find(setting => (setting.name === addSetting) && (setting.language === language));
    if (setting) footerMessages = ((setting.default as string).replace(/\n/g, '<br/>'));
  }
  return footerMessages;
}

export function assertCondition(condition: boolean, message: string): asserts condition {
  if (!condition) {
    throw new Error(message);
  }
}
