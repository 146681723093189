import {inject, Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "./user.service";
import {ILanguage, SupportedLanguages} from "core/models/setting";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private translateService = inject(TranslateService);
  private userService = inject(UserService);

  public getPossibleLanguages(): ILanguage[] {
    return [
      {value: SupportedLanguages.en, label: 'English', short: 'EN'},
      {value: SupportedLanguages.nl, label: 'Nederlands', short: 'NL'},
      {value: SupportedLanguages.fr, label: 'Français', short: 'FR'}
    ];
  }

  public getCurrentLanguage(): ILanguage {
    const current = this.translateService.currentLang as SupportedLanguages;
    const languages = this.getPossibleLanguages();

    return languages.find(item => {
      return item.value === current;
    });
  }

  public getSupportedLanguage(language: string): SupportedLanguages {
    if (['nl-BE', 'fr-BE', 'en-BE'].includes(language)) return language as SupportedLanguages;
    return SupportedLanguages.nl;
  }

  /**
   * gets the browser language if supported, else default
   */
  private getBrowserLanguage() {
    return this.getSupportedLanguage(this.translateService.getBrowserLang());
  }

  private getUserLanguage(): SupportedLanguages {
    const userLanguage = this.userService.getLocalUserData()?.language;
    if (!userLanguage) return null;
    return this.getSupportedLanguage(userLanguage);
  }

  public setLanguage(language: SupportedLanguages) {
    this.setTranslateServiceLang(language);
    this.userService.setLanguage(language);
  }

  public getLanguage(): SupportedLanguages {
    const userLanguage = this.getUserLanguage();
    const browserLanguage = this.getBrowserLanguage();
    return userLanguage || browserLanguage;
  }

  public initializeTranslateService() {
    this.setTranslateServiceLang(this.getLanguage());
  }

  private setTranslateServiceLang(language: SupportedLanguages) {
    const json = require(`../../assets/i18n/${language}.json`);
    this.translateService.setTranslation(language, json, false);
    this.translateService.use(language);
  }
}
