import {Injectable} from '@angular/core';
import {CognitoAccessToken, CognitoIdToken, CognitoRefreshToken} from 'amazon-cognito-identity-js';
import {IRegisterData, IUser, IUserData, kEmptyUser} from "core/models/user";
import {SupportedLanguages} from "core/models/setting";
import {_accessTokenKey, _idTokenKey, _refreshTokenKey, _registerKey, _userKey} from "core/localstorage-keys";
import {checkCompanySubscription, ICompany} from "core/models/company";
import {EventService} from "./event.service";
import { IEmployee } from '../../../../core/models/employee';

@Injectable({
  providedIn: 'root'
})
export class UserService extends EventService {
  private user: IUser = null;
  private company: ICompany = null;
  private employee: IEmployee = null;
  private idToken: CognitoIdToken = null;
  private accessToken: CognitoAccessToken = null;
  private refreshToken: CognitoRefreshToken = null;

  // only used during registration, please never save it as such in localStorage (it contains the password !!)
  private register: IRegisterData;

  constructor() {
    super();
    this.getUserData();
    this.fetchTokens();
  }

  public getCompany(): ICompany {
    return this.company;
  }

  public getEmployee(): IEmployee {
    return this.employee;
  }

  public getUser(): IUser {
    return this.user;
  }

  public getIdToken(): CognitoIdToken {
    if (!this.idToken) this.fetchTokens();
    return this.idToken;
  }

  public getAccessToken(): CognitoAccessToken {
    //if (!this.accessToken)
    try {
      this.fetchTokens();
      return this.accessToken;

    } catch(e) {
      return null;
    }
  }

  public getRefreshToken(): CognitoRefreshToken {
    if (!this.refreshToken) this.fetchTokens();
    return this.refreshToken;
  }

  public logout() {
    this.storeTokens(null, null, null);
  }

  public login(idToken: CognitoIdToken, accessToken: CognitoAccessToken, refreshToken: CognitoRefreshToken) {
    this.storeTokens(idToken, accessToken, refreshToken);
  }

  public setLanguage(language: SupportedLanguages) {
    this.getUserData();
    this.user.language = language;
    this.setUserData(this.user);
  }

  public isLoggedIn(): boolean {
    return !!this.idToken;
  }

  public subscriptionOK(): boolean {
    return checkCompanySubscription(this.company);
  }

  public storeTokens(idToken: CognitoIdToken, accessToken: CognitoAccessToken, refreshToken: CognitoRefreshToken) {
    // remove the tokens that are null, otherwise store (or override) them
    this.idToken = idToken;
    if (idToken) {
      localStorage.setItem(_idTokenKey, this.idToken?.getJwtToken() || "");
    } else {
      localStorage.removeItem(_idTokenKey);
    }

    this.accessToken = accessToken;
    if (accessToken) {
      localStorage.setItem(_accessTokenKey, this.accessToken?.getJwtToken() || "");
    } else {
      localStorage.removeItem(_accessTokenKey);
    }

    this.refreshToken = refreshToken;
    if (refreshToken) {
      localStorage.setItem(_refreshTokenKey, this.refreshToken?.getToken() || "");
    } else {
      localStorage.removeItem(_refreshTokenKey);
    }
  }

  public fetchTokens() {
    const IdToken = localStorage.getItem(_idTokenKey);
    if (IdToken) this.idToken = new CognitoIdToken({IdToken});

    const AccessToken = localStorage.getItem(_accessTokenKey);
    if (AccessToken) this.accessToken = new CognitoAccessToken({AccessToken});

    const RefreshToken = localStorage.getItem(_refreshTokenKey);
    if (RefreshToken) this.refreshToken = new CognitoRefreshToken({RefreshToken});
  }

  public getLocalUserData(): IUserData {
    try {
      return JSON.parse(localStorage.getItem(_userKey));
    } catch (err) {
      return null;
    }
  }

  public getUserData(): IUser {
    this.user = {...kEmptyUser, ...this.getLocalUserData(), ...this.user};
    return this.user;
  }

  public setUserData(user: Partial<IUserData>, company: ICompany = null, employee: IEmployee = null) {
    if (user) {
      this.user = {...kEmptyUser, ...this.user, ...user};
      if (company) this.company = company;
      if (employee) this.employee = employee;
      localStorage.setItem(_userKey, JSON.stringify(this.user));

    } else {
      localStorage.removeItem(_userKey);
    }
  }

  public setRegisterData(register: Partial<IRegisterData>) {
    if (register.email) localStorage.setItem(_registerKey, register.email);
    this.register = {...this.user, ...this.register, ...register};
  }
}
